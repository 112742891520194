// helpers.js
// Public holidays data
const publicHolidays = [
    new Date("2025-01-01"), // New Year's Day - Wednesday, January 1, 2025
    new Date("2025-02-17"), // Family Day - Monday, February 17, 2025
    new Date("2025-04-18"), // Good Friday - Friday, April 18, 2025
    new Date("2025-05-19"), // Victoria Day - Monday, May 19, 2025
    new Date("2025-07-01"), // Canada Day - Tuesday, July 1, 2025
    new Date("2025-08-04"), // B.C. Day - Monday, August 4, 2025
    new Date("2025-09-01"), // Labour Day - Monday, September 1, 2025
    new Date("2025-09-30"), // National Day for Truth and Reconciliation - Tuesday, September 30, 2025
    new Date("2025-10-13"), // Thanksgiving Day - Monday, October 13, 2025
    new Date("2025-11-11"), // Remembrance Day - Tuesday, November 11, 2025
    new Date("2025-12-25"), // Christmas Day - Thursday, December 25, 2025
    // Add more holidays as needed
];

// Delivery cost data for different cities
// Delivery cost data for different cities
const deliveryCosts = {
    "Surrey, British Columbia": { monFri: 15, sameDay: 20, weekendHoliday: 25, weekendSameDay: 30 },
    "Burnaby, British Columbia": { monFri: 25, sameDay: 30, weekendHoliday: 35, weekendSameDay: 40 },
    "Maple Ridge, British Columbia": { monFri: 25, sameDay: 30, weekendHoliday: 35, weekendSameDay: 40 },
    "Port Coquitlam": { monFri: 30, sameDay: 35, weekendHoliday: 40, weekendSameDay: 45 },
    "Richmond, British Columbia": { monFri: 30, sameDay: 35, weekendHoliday: 40, weekendSameDay: 45 },
    "White Rock, British Columbia": { monFri: 25, sameDay: 30, weekendHoliday: 35, weekendSameDay: 40 },
    "Langley, British Columbia": { monFri: 25, sameDay: 30, weekendHoliday: 35, weekendSameDay: 40 },
    "Walnut Grove": { monFri: 30, sameDay: 35, weekendHoliday: 40, weekendSameDay: 45 },
    "Coquitlam": { monFri: 30, sameDay: 35, weekendHoliday: 40, weekendSameDay: 45 },
    "Delta, British Columbia": { monFri: 25, sameDay: 30, weekendHoliday: 35, weekendSameDay: 40 },
    "New Westminster": { monFri: 25, sameDay: 30, weekendHoliday: 35, weekendSameDay: 40 },
    "Port Moody": { monFri: 30, sameDay: 35, weekendHoliday: 40, weekendSameDay: 45 },
    "Vancouver": { monFri: 40, sameDay: 45, weekendHoliday: 50, weekendSameDay: 55 },
    "Ladner, British Columbia": { monFri: 30, sameDay: 35, weekendHoliday: 40, weekendSameDay: 45 },
    "West Vancouver": { monFri: 40, sameDay: 45, weekendHoliday: 50, weekendSameDay: 55 },
    "East Vancouver": { monFri: 40, sameDay: 45, weekendHoliday: 50, weekendSameDay: 55 },
    "North Vancouver": { monFri: 40, sameDay: 45, weekendHoliday: 50, weekendSameDay: 55 },
    "Abbotsford, British Columbia": { monFri: 15, sameDay: 20, weekendHoliday: 25, weekendSameDay: 30 },
    "Downtown Vancouver": { monFri: 40, sameDay: 45, weekendHoliday: 50, weekendSameDay: 55 },
    "Pitt Meadows": { monFri: 35, sameDay: 40, weekendHoliday: 45, weekendSameDay: 50 },
    "Mission, British Columbia": { monFri: 40, sameDay: 45, weekendHoliday: 50, weekendSameDay: 55 },
    "Aldergrove, County Antrim": { monFri: 25, sameDay: 30, weekendHoliday: 35, weekendSameDay: 40 },
    "Abbotsford": { monFri: 15, sameDay: 20, weekendHoliday: 25, weekendSameDay: 30 },
    "Surrey": { monFri: 15, sameDay: 20, weekendHoliday: 25, weekendSameDay: 30 }
    // Add other cities as necessary
};

// Utility function to parse date in YYYY-MM-DD format
const parseDate = (dateString) => {
    const [year, month, day] = dateString.split('-').map(Number);
    // Months are 0-indexed, so subtract 1
    return new Date(year, month - 1, day);
};

// Function to calculate delivery cost
const calculateDeliveryCost = (city, deliveryDate) => {
    // Use utility function to parse date
    const date = parseDate(deliveryDate);
    console.log(`Delivery Date Input: ${deliveryDate}`);
    console.log(`Parsed Date: ${date.toDateString()}`);

    const dayOfWeek = date.getDay(); // 0 for Sunday, 6 for Saturday
    console.log(`Day of Week: ${dayOfWeek}`);

    // Determine if the day is a public holiday
    const isHoliday = publicHolidays.some(holiday =>
        holiday.toDateString() === date.toDateString()
    );
    console.log(`Is Holiday: ${isHoliday}`);

    // Determine the type of day
    let dayType;
    if (isHoliday || dayOfWeek === 0 || dayOfWeek === 6) {
        dayType = 'weekendHoliday'; // Weekend or Holiday
    } else {
        dayType = 'monFri'; // Weekday
    }

    // Check if same-day delivery is applicable
    const now = new Date();
    const isSameDay = now.toDateString() === date.toDateString();
    if (isSameDay) {
        dayType = (dayOfWeek === 0 || dayOfWeek === 6) ? 'weekendSameDay' : 'sameDay';
    }
    console.log(`Is Same Day: ${isSameDay}, Day Type: ${dayType}`);

    // Fetch the delivery cost based on city and day type
    const cityCosts = deliveryCosts[city];
    if (!cityCosts) {
        console.error(`City not found: ${city}`);
        return 0;
    }

    const deliveryCost = cityCosts[dayType];
    if (deliveryCost === undefined) {
        console.error(`Delivery type not found: ${dayType} for city: ${city}`);
        return 0;
    }

    console.log(`City: ${city}, Delivery Cost for ${dayType}: ${deliveryCost}`);
    return deliveryCost;
};

// Function to get query parameters
export const getPriceQueryParams = (searchParams, key, value) => {
    const hasValueInParam = searchParams.has(key);

    if (value && hasValueInParam) {
        searchParams.set(key, value);
    } else if (value) {
        searchParams.append(key, value);
    } else if (hasValueInParam) {
        searchParams.delete(key);
    }
    return searchParams;
};

export const calculateOrderCost = (cartItems, shippingInfo) => {
    const itemsPrice = cartItems?.reduce(
        (acc, item) => acc + item.price * item.quantity,
        0
    );

    // Calculate delivery cost
    const deliveryCost = calculateDeliveryCost(shippingInfo?.city, shippingInfo?.deliveryDate);

    // Calculate tax
    const tax = 0; // set to 0 for now, can be updated later

    // Calculate total price
    const totalPrice = (itemsPrice + deliveryCost + (itemsPrice * tax / 100)).toFixed(2);

    return {
        itemsPrice,
        deliveryCost,
        tax,
        totalPrice
    };
};